<template>
	<page-container :page="page" :locale="locale" class="index">
		<heading-image>
			<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
				<p>
					<span>{{ defaults[locale].homepage.headingImageTitle }}</span>
				</p>
				<div v-parse-links v-html="defaults[locale].homepage.headingImageContent ?? ''" />
			</div>
			<video-header v-if="page.embedCode" :video="page.embedCode" />
			<hero-image v-else-if="page.headingImage" type="fullscreen">
				<div class="slider-overlay" />
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img class="object-fit" :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>

			<booking-form :booking-form="defaults[locale].bookingForm" />
		</heading-image>

		<main-content :title="page.title || page.header" :content="page.content">
			<template #content>
				<div class="columns column6 align-left">
					<h1>{{ page.title }}</h1>
					<slot name="extra-heading" />
					<div v-parse-links v-html="page.content" />
					<slot name="extra-content" />
				</div>
				<div class="columns column6 intro-image">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
			</template>
		</main-content>

		<section v-if="defaults[locale].homepage.benefit.length" class="bookingbenefits">
			<div class="row">
				<div v-for="benefit in defaults[locale].homepage.benefit" :key="benefit.ID" class="columns column4">
					<font-awesome-icon :icon="benefit.icon" size="1x" />
					<div v-parse-links v-html="benefit.content" />
				</div>
			</div>
		</section>

		<promoblocks :items="page.promoblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>{{ defaults[locale].homepage.sectionPromotionTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent" />
					</div>
				</div>
			</template>
		</promoblocks>

		<contentblocks :items="page.contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionContentblocksContent" />
					</div>
				</div>
			</template>
		</contentblocks>

		<section v-if="reviewsData.length" class="reviews-section">
			<div class="row">
				<div class="columns column12 align-center">
					<h2>{{ defaults[locale].homepage.reviewsHeader }}</h2>
				</div>
			</div>
			<div class="row">
				<client-only>
					<carousel ref="carousel" :items-to-show="1" :wrap-around="true" class="review-slider">
						<slide v-for="item in reviewsData" :key="item.ID">
							<div class="slider-item align-center">
								<p>{{ item.content }}</p>
								<div class="review-source">
									<div class="logo" :class="item.source" />
									<div>
										<strong>{{ item.firstName }}</strong
										><br />
										<span v-if="item.date">
											{{ $d(new Date(item.date.date.split(' ')[0]), 'short', locale) }}
										</span>
									</div>
								</div>
							</div>
						</slide>

						<template #addons>
							<pagination />
						</template>
					</carousel>
				</client-only>
			</div>
		</section>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

const { data: reviewsData } = await useWebsiteFetch('ReviewsHomepage', {
	query: { language: locale.value },
	key: `${locale.value}/ReviewsHomepage`,
});

await fetchDefaults();
await fetchPage();
</script>

<style lang="scss" scoped>
.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 2;
	background: rgb(0 0 0 / 25%);
}

:deep(.intro) {
	padding: 20px 0;

	.column6:first-child {
		padding: 40px 40px 40px 20px;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: flex-start;
	}

	.row {
		max-width: 1480px;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: stretch;
	}
}

.reviews-section {
	background: url('~/assets/images/review-background-image.jpg') no-repeat center center;
	background-size: cover;
	position: relative;
	padding: 100px 0 25px;

	&::before {
		background: rgba(15 55 85 / 80%);
		backdrop-filter: blur(38px);
		content: '';
		display: block;
		position: absolute;
		inset: 0;
		z-index: 1;
	}

	.row {
		position: relative;
		z-index: 2;
	}

	.review-source {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		text-align: left;
		gap: 15px;
		margin: 34px auto 0;

		strong {
			margin: 0 0 5px;
			display: inline-block;
		}

		.logo {
			width: 30px;
			height: 30px;
			background-size: 30px 30px;

			&.tripadvisor {
				background-image: url('~/assets/images/review-logo-tripadvisor.png');
			}

			&.bookingcom {
				background-image: url('~/assets/images/review-logo-bookingcom.png');
			}

			&.zoover {
				background-image: url('~/assets/images/review-logo-zoover.png');
			}

			&.expedia {
				background-image: url('~/assets/images/review-logo-expedia.png');
			}

			&.google {
				background-image: url('~/assets/images/review-logo-google.png');
			}
		}
	}

	.carousel {
		padding: 25px 0;
		max-width: 760px;
		text-align: center;
		margin: 0 auto;
	}

	h2 {
		color: #fff;
	}

	.slider-item {
		color: #fff;
	}
}

/* stylelint-disable selector-class-pattern */
.carousel__pagination {
	margin: 120px 0 0;
}

:deep(.carousel__pagination-item) {
	margin: 0 4px;

	button {
		width: 16px;
		height: 16px;
		border: 1px solid #fff;
		border-radius: 50%;

		&.carousel__pagination-button--active {
			background: #fff;
		}

		&::after {
			display: none;
		}
	}
}
/* stylelint-enable selector-class-pattern */

.bookingbenefits {
	background: var(--secondary-background-color);
	padding: 25px 0;

	.row {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: stretch;
	}

	.column4 {
		text-align: center;
		background: var(--dark-background-color);
		width: 32.5%;
		color: #fff;
		padding: 100px 50px;
	}

	svg {
		margin: 0 auto 30px;
		color: var(--cta-color);
		font-size: 44px;
	}
}

.blocks-header {
	.column12 {
		flex-flow: column wrap;
	}
}

.hero-content {
	position: absolute;
	max-width: 1400px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 50%;
	margin: 0 auto -70px;
	text-align: center;
	color: #fff;
	letter-spacing: 2px;

	:deep(span) {
		color: #fff;
		font-size: 2.4em;
		font-family: var(--heading-font-family);
		font-weight: 400;
		margin: 0 0 10px;
		width: 100%;
		display: inline-block;
		line-height: 1;
	}

	:deep(p) {
		font-size: 1.8em;
	}
}

@media (max-width: 1080px) {
	.bookingbenefits {
		.column4 {
			padding: 75px 30px;
		}
	}
}

@media (max-width: 880px) {
	.hero-content {
		:deep(span) {
			font-size: 1.8em;
		}

		:deep(p) {
			font-size: 1.6em;
			padding: 0 10px;
		}
	}

	.bookingbenefits {
		.column4 {
			padding: 80px 35px;
			margin: 10px auto;
			width: 96%;
			max-width: 500px;
		}
	}
}
</style>
